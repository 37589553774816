@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Rubik', sans-serif;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
}

.social-icon {
  size: 1rem;
}

.h-112 {
  height: 40rem;
}

.max-h-112 {
  max-height: 40rem;
}

.min-h-250px {
  min-height: 250px;
}

.banner-height-phone {
  height: calc(100vh - 230px);
}
.banner-height-tablet {
  height: calc(100vh - 303px);
}
.big-button {
  width: 8rem; /* w-16 */
  height: 8rem; /* h-16 */
  aspect-ratio: 1/1; /* aspect-w-1 aspect-h-1 */
  background: #4f46e5; /* bg-indigo-600 */
  border-radius: 9999px; /* rounded-full */
  cursor: pointer; /* cursor-pointer */
  user-select: none; /* select-none */
  transition: all 150ms; /* transition-all duration-150 */
  box-shadow:
    0 0.5rem 0 0 #4f46e5,
    0 0.813rem 0 0 #1b70f841; /* box-shadow:0_8px_0_0_#1b6ff8,0_13px_0_0_#1b70f841 */
  border: 0.063rem solid #a5b4fc; /* border-[1px] border-indigo-300 */
}

.big-button:active {
  transform: translateY(0.125rem); /* active:translate-y-2 */
  box-shadow:
    0 0px 0 0 #a5b4fc,
    0 0px 0 0 #1b70f841; /* active:[box-shadow:0_0px_0_0_#1b6ff8,0_0px_0_0_#1b70f841] */
  border-bottom-width: 0px; /* active:border-b-[0px] */
}

.big-button span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white; /* text-white */
  font-weight: bold; /* font-bold */
  font-size: 1.125rem; /* text-lg */
}

.big-button span:hover {
  color: #a5b4fc; /* hover:text-indigo-300 */
}

.table-bordered th,
.table-bordered tr,
.table-bordered td {
  border: 0.5px rgb(100 116 139) solid; /* border slate-500*/
  padding-left: 5px;
}

.table-text-center th,
.table-text-center td {
  text-align: center;
}

.slick-slider .slick-prev,
.slick-slider .slick-next {
  background-color: white;
  border-radius: 0px;
  padding-top: 0px;
}

/* Hover and active state for both previous and next arrows */
.slick-slider .slick-prev:hover,
.slick-slider .slick-next:hover,
.slick-slider .slick-prev:active,
.slick-slider .slick-next:active {
  background-color: rgb(67, 56, 202); /* Desired background color */
  border-radius: 0px; /* Desired border radius */
  padding-top: 0px; /* Desired padding-top */
  color: inherit; /* Assuming you want the text color to stay the same */
  outline: none; /* Removes the outline that browsers might add on click */
}

/* Additional styles to override any other specific :active styles if needed */
.slick-slider .slick-prev:focus,
.slick-slider .slick-next:focus {
  outline: none; /* Removes the outline that browsers might add on focus */
}
